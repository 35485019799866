/*

 Copyright (C) 2015  Thien Nguyen

 This program is free software: you can redistribute it and/or modify
 it under the terms of the GNU General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 This program is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU General Public License for more details.

 You should have received a copy of the GNU General Public License
 along with this program.  If not, see <http://www.gnu.org/licenses/>

 */

var shinyObjects = {

    addShine: function (elem, blur, shineStrength) {

        function fire(event, $this, blur, shineStrength) {
            var width = $this.width(),
                height = $this.height();
            var layers = $this.find('div[class*="layer-"]');
            var shinyObject = $this.find('.shiny-object');
            var shinyEffect = $this.find('.shine-effect');
            var posX = event.pageX - $this.offset().left,
                posY = event.pageY - $this.offset().top;
            var offsetX = (width * 2) - event.pageX;
            var tranY = -1 - (posX / 140);
            var tranY2 = 1 - ((-offsetX - 10) / 280);

            //3 should be max with tblr @ -20px
            var rotX = 1 - (posX * 3) / width;
            var rotY = 1 - (posY * 3) / height;

            var transformTranslate = 'translateY(' + tranY + 'px)';
            var transformRotate = 'rotateX(' + -rotY + 'deg) rotateY(' + rotX + 'deg)';

            shinyObject.css({transform: transformTranslate + transformRotate});

            var top = parseInt(layers.css('top'));

            //blur option - requires int
            if (blur != 'undefined') {
                if (typeof blur == 'number' && blur != 0) {
                    layers.last().css({
                        '-webkit-filter': 'blur(' + blur + 'px',
                        'filter': 'blur(' + blur + 'px)'
                    });
                }
            }

            if (shineStrength == 'undefined') {
                shineStrength = 0.5;
            }

            layers.each(function () {

                var layerElement = $(this);
                var offsetLayer = parseInt(layerElement.data('offset')) || 0;

                if (offsetLayer > 0) {
                    if (offsetLayer > -top * 3) {
                        var val = -top + -top + top;
                        offsetLayer = val / 2;
                    }
                } else {
                    if (offsetLayer < (top / 2)) {
                        offsetLayer = top / 2;
                    }
                }

                var transformLayer = 'translateX(' + rotX * offsetLayer + 'px) translateY(' + rotY * offsetLayer + 'px)';

                layerElement.css({transform: transformLayer});

            });

            var radian = Math.atan2(posY - height / 2, posX - width / 2);
            var angle = radian * 180 / Math.PI - 90;

            shinyEffect.css('background', 'linear-gradient(' + angle + 'deg, rgba(255,255,255,' + shineStrength + ') 0%,rgba(255,255,255,0) 80%)');
        }

        elem.on('mousemove', function (event) {

            $this = $(this);

            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            } else {
                fire(event, $this, blur, shineStrength);
            }

        });

        elem.on('mouseleave', function () {

            function roundToOne(num) {
                return +(Math.round(num + "e+1") + "e-1");
            }

            function roundToThree(num) {
                return +(Math.round(num + "e+3") + "e-3");
            }

            var layers = $(this).find('div').not('.shiny-object').not('.shine-effect');
            var shinyObject = $(this).find('.shiny-object');

            var transValue = shinyObject.css('transform');
            var transplode = transValue.split(',');
            var tranX = parseInt(transplode[12]);
            var tranY = parseInt(transplode[13]);
            //var rotXA = parseFloat(transplode[0].split('(')[1]);
            var rotYA = parseFloat(transplode[8]);
            var rotXA = parseFloat(transplode[1]);

            //This is the incorrect value
            var angleX = Math.asin(rotXA) * (180 / Math.PI);

            var angleY = Math.asin(rotYA) * (180 / Math.PI);

            var checkTran = function () {

                if (tranY != 0 || roundToOne(angleY) != 0 || roundToThree(angleX) < 0) {

                    if (roundToThree(angleX) < 0) {
                        if (angleX > 0) {
                            angleX -= 0.01;
                        } else {
                            angleX += 0.01;
                        }
                    }

                    if (roundToOne(angleY) != 0) {
                        if (angleY > 0) {
                            angleY -= 0.1;
                        } else {
                            angleY += 0.1;
                        }
                    }

                    if (tranY != 0) {
                        if (tranY > 0) {
                            parseInt(tranY -= 0.5);
                        } else {
                            parseInt(tranY += 0.5);
                        }
                    }

                    shinyObject.css({transform: 'translateY(' + tranY + 'px)' + 'rotateX(' + angleX + 'deg)' + 'rotateY(' + angleY + 'deg)'});

                } else {
                    clearInterval(timer);
                }
            };

            var timer = setInterval(checkTran, 50);

            layers.each(function () {

                var $this = $(this);
                var transValue = $(this).css('transform');
                var transplode = transValue.split(',');
                var tranX = parseInt(transplode[4]);
                var tranY = parseInt(transplode[5]);

                var checkLayerTran = function () {

                    if (tranX != 0 || tranY != 0) {
                        if (tranX != 0) {
                            if (tranX > 0) {
                                tranX--;
                            } else {
                                tranX++;
                            }
                        }

                        if (tranY != 0) {
                            if (tranY > 0) {
                                tranY--;
                            } else {
                                tranY++;
                            }
                        }

                        $this.css({transform: 'translateX(' + tranX + 'px) translateY(' + tranY + 'px)'});

                    } else {
                        clearInterval(timer);
                    }
                };

                var timer = setInterval(checkLayerTran, 50);

            });

            if (blur != 'undefined') {
                if (typeof blur == 'number' && blur != 0) {
                    layers.last().css({
                        '-webkit-filter': 'blur(0px',
                        'filter': 'blur(0px)'
                    });
                }
            }

            $(this).unbind("fire");
        });
    }
};

